*,
*:before,
*:after{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

#root{
    height: 100%;
}

body{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Raleway", serif!important;
}

h1, h2, h3, h4, a, ::placeholder, .ui.header, .ui.button, .ui.menu .item, .header, .ui.accordion .title{
    font-family: "Raleway", sans-serif!important;
}

.ui.header{
    .sub.header{
        margin-top: 8px;
    }
}

.carousel .slide{
    background: #fff!important;
}

.carousel .control-dots .dot{
    background: #333!important;
}

.ui.button{
    letter-spacing: 1px!important;
}

.title-color{
    color: #263238!important;
}

.input-field-error{
    color: #e0102b;
    font-size: 13px;
    font-weight: bold;
    margin-top: 6px;
}

.ui.input{
    width: 100%;
}

.ui.input.error>input{
    border-color: #e0102b!important;
    background-color: #fff!important;
    color: #000!important;
}

.ui.search .prompt{
    border-radius: .28571429rem;
}

.accounts-input{
    margin-bottom: 20px;
        
    label{
        color: #263238;
        display: inline-block;
        margin-bottom: 6px;
    }

    &-radio{
        margin-right: 20px;
        display: inline-block;
    }
}

.hidden-elem{
    display: none;
}

.util-bold{
    font-weight: bold;
}

.util-medium{
    font-weight: medium;
}

.btn-primary-dark{
    background: #17202b!important;
    color: #fff!important;
    font-weight: normal!important;
    
    &:hover{
        background: lighten($color: #17202b, $amount: 5)!important;
    }
}