.uploader-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.Method--card{
    cursor: pointer;
    margin-bottom: 20px!important;

    &--content{
        display: flex;
        align-items: center;
    
        img{
            margin: 0px 10px;
        }
    
        h3{
            margin: 0px;
        }
    }
}


.uploader-container{
    padding: 40px 20px;

    @media only screen and (max-width: 420px){
        padding: 0px;
    }
}

.preview-container{
    padding-bottom: 20px;

    &--left{

        @media only screen and (max-width: 420px){
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.preview-borders{
    display: inline-block;
    position: relative;
    text-align: center;
    cursor: pointer;
    
    .preview-covers{
        position: relative;
        width: 100%;
        z-index: 1;
        width: 236px;
        height: 236px;

        @media only screen and (max-width: 420px){
            width: 136px;
            height: 136px;
        }

        img{
            border-radius: 3px;
            height: 100%;
            width: 100%;
            vertical-align: middle;
            display: inline-block;
            object-fit: cover;
            object-position: center;
        }
    }

    &::before, &::after{
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        border: solid 3px #cccccc;
        background-color: white;
        box-shadow: 1px 1px 0px white;
    }

    &::before{
        top: 8px;
        left: 9px;
    }

    &::after{
        top: 4px;
        left: 5px;
    }
}

.images-methods{
    padding-right: 30px;

    @media only screen and (max-width: 420px){
        padding-right: 0px;
    }

    &--title{
        font-size: 20px;
        color: #353535;
    }
}

.photo-method-segment{
    cursor: pointer!important;
    transition: all 0.2s!important;
    
    &:hover{
        transform: translateY(-2px);
    }
}

.photo-method{
    display: flex;
    align-items: center;

    .method-icons{
        height: 30px;
    }

    p{
        color: #1C2833;
        margin-left: 10px;
        font-size: 17px;
    }
}