
.NavBar--sidebar-icon{
    margin-left: 10px !important;
}

.NavBar{
    .ui.menu{
        margin: 0rem;
        border-radius: 0px;
    }
}

.notifications{

    .ui.floating.label{
        top: 2em;
        left: 80%;
    }
}

.SideNavBar{
    padding: 10px 20px;

    &--header{
        margin: 20px 0px;
    }

    &--link{
        margin-bottom: 10px;
    }
}