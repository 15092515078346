.InviteIcon{
    display: flex;
    flex-direction: column;
    align-items: center;

    img{
        margin-bottom: 20px;
    }
}

.InviteModal--container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 30px;
}

.CopyContainer{
    padding: 0px 50px;
    margin-top: 20px;

    @media only screen and (max-width: 720px){
        padding: 0px 0px;
    }
}